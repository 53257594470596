.app {
  display: grid;
  grid-template-areas:
    'header'
    'content';
  grid-template-columns: 1fr;
  gap: .2fr;
  padding: 1rem;
  max-width: 900px;
  margin: 0 auto;
}

.header {
  grid-area: header;
}

.content {
  grid-area: content;
}