.header {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: solid 1px darkgray;
}

.logo {
  font-size: 1.1em;
  padding: .5em .4em;
  width: 50px;
  height: 50px;
}

.logo.spinning {
  animation: spin .6s linear infinite;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin-bounce {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  80% {
    -webkit-transform: rotate(400deg);
    transform: rotate(400deg);
  }
  90% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.navigation {
  flex-grow: 1;
  list-style-type: none;
}

.navigation>ul {
  display: flex;
  flex-flow: row;
  flex-direction: row;
  gap: 1rem;
  list-style-type: none;
  margin: .8em;
  padding: .2em .4em;
}

.navigation ul>li {
  display: block;
}

.navigation ul>li>a {
  display: block;
  color: black;
  text-decoration: none;
}

.navigation ul>li>a:hover {
  display: block;
  color: black;
  text-decoration: underline;
}