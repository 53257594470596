.articles {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.articles > li {
  padding: 0;
  margin: 0 0 2rem 0;
}

.articles > li > h2 {
  padding: 0;
  margin: 0;
}

.articles > li > h2 > a {
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: black;
}

.articles > li > h2 > a:hover {
  text-decoration: underline;
}

.articles > li > .publishDate {
  font-size: .9em;
  color: darkgray;
  padding: 0;
  margin: 0;
}

.articles > li > .preview {
  font-size: 1em;
  text-align: justify;
  padding: 0;
  margin: 0;
}

